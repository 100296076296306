import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import { setProject, setRotationMultiplier } from "../state/store"

import { toggleInfo, toggleProjects } from "../state/store"
import { Projects, Modal } from "../components"

const Index = ({
  dispatch,
  showInfo,
  showProjects,
  project,
  data,
  location,
}) => {
  useEffect(() => {
    document.body.classList.add("is-home")
    let timeoutId
    if (project) {
      timeoutId = setTimeout(() => {
        dispatch(toggleProjects(true))
      }, 900)
    }
    dispatch(setProject(null))
    dispatch(setRotationMultiplier(10))
    if (typeof document !== "undefined")
      document.body.classList.remove("is-project")

    return () => {
      timeoutId && clearTimeout(timeoutId)
      document.body.classList.remove("is-home")
    }
  }, [])

  const onClickProjects = () => {
    dispatch(toggleProjects(true))
  }

  const onClickInfo = () => {
    dispatch(toggleInfo(true))
  }

  const onClickOutside = () => {
    showInfo && dispatch(toggleInfo(false))
    showProjects && dispatch(toggleProjects(false))
  }

  const onClickInside = (e) => {
    e.stopPropagation()
  }

  const { edges: projects } = data.all
  const { index } = data

  return (
    <div className="index" onClick={onClickOutside}>
      {/*
        <div className="debug">
          <button onClick={onClickProjects}>projects</button>
          <button onClick={onClickInfo}>info</button>
        </div>
        */}
      <h1
        className="index__title"
        dangerouslySetInnerHTML={{ __html: index.frontmatter.intro }}
      ></h1>

      <Modal
        className={`index__projects is-projects-modal ${
          showProjects ? "is-active" : ""
        }`}
        onClick={onClickInside}
        onClickClose={onClickOutside}
      >
        <Projects projects={projects} />
      </Modal>

      <Modal
        className={`index__info ${showInfo ? "is-active" : ""}`}
        onClick={onClickInside}
        onClickClose={onClickOutside}
      >
        <div
          className="modal-content wysiwyg"
          dangerouslySetInnerHTML={{ __html: index.html }}
        ></div>
      </Modal>
    </div>
  )
}

export default connect(
  (state) => ({
    showInfo: state.showInfo,
    showProjects: state.showProjects,
    project: state.project,
  }),
  null
)(Index)

export const query = graphql`
  query {
    all: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 100
      filter: { frontmatter: { title: { ne: "index" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            video_thumbnail
          }
        }
      }
    }
    index: markdownRemark(frontmatter: { title: { eq: "index" } }) {
      frontmatter {
        intro
        instagram
        mail
      }
      html
    }
  }
`
